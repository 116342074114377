<template>
  <div id="detail" style="">
    <!-- <div class="top">
      <img class="returns" src="@/assets/return.png" alt="" @click="back">
      <img class="logo" src="https://static.ainvestcn.com/h5/topLogo%402x.fdcb6507.png" alt="">
    </div> -->
    <van-popup v-model="datePickerShow1" :style="{width:'80%'}">
      <van-datetime-picker
        v-model="qrDate1"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="confirmDate('qrDate1', 'datePickerShow1')"
        @cancel="cancelDate('qrDate1', 'datePickerShow1')"
      />
    </van-popup>

    <van-popup v-model="datePickerShow2" :style="{width:'80%'}">
      <van-datetime-picker
        v-model="qrDate2"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="confirmDate('qrDate2', 'datePickerShow2')"
        @cancel="cancelDate('qrDate2', 'datePickerShow2')"
      />
    </van-popup>
    <div class="mid" style="padding-bottom:50px">
      <div class="card">
        <van-cell>
          <h2
            slot="title"
            style="margin-bottom:10px"
            class="cardtitle"
          >
            {{ strategyinfo.strategy_name }}</h2>
          <template slot="label">
            <p style="margin:3px 0 0 0;color:grey">{{ strategyinfo.strategy_introduction }}</p>
          </template>
        </van-cell>
      </div>
      <div>
        <van-card>
          <div slot="desc">
            <div>
              <h3>
                <span style>回测效果</span>
                <van-tag plain style="float:right">回测天数 {{ strategyinfo.measure_days }}</van-tag>
              </h3>
            </div>
            <van-tag
              color="#f2826a"
              plain
              style="margin-right:5px;margin-bottom:5px"
            >累计收益率 {{ (parseFloat( strategyinfo.total_return_value)/100).toFixed(2)+'%' }}</van-tag>
            <van-tag
              color="#f2826a"
              plain
              style="margin-right:5px;margin-bottom:5px"
            >夏普值 {{ strategyinfo.sharp_value }}</van-tag>
            <van-tag
              color="#f2826a"
              plain
              style="margin-right:5px;margin-bottom:5px"
            >日收益率 {{ strategyinfo.daily_return_value }}</van-tag>
            <van-tag
              color="#f2826a"
              plain
              style="margin-right:5px;margin-bottom:5px"
            >年化回报率 {{ strategyinfo.annualized_return_value }}</van-tag>
            <van-tag
              color="#f2826a"
              plain
              style="margin-right:5px;margin-bottom:5px"
            >年化波动率 {{ strategyinfo.annualized_volatility_value }}</van-tag>
            <van-tag
              color="#f2826a"
              plain
              style="margin-right:5px;margin-bottom:5px"
            >最大回撤 {{ strategyinfo.maximum_drawdown_value }}</van-tag>
          </div>
          <!-- <h3> Tips: 适用金额 20 万</h3> -->
          <!-- <div slot="footer">适用金额：{{ strategyinfo.cash }}</div> -->
        </van-card>
        <div class="card" style="">
          <div id="pig" class="line" :style="{width:cubeWidth,height:'200px'}" />
        </div>
      </div>
      <van-tabs v-model="active">
        <van-tab title="历史持仓">
          <!-- 日期 -->
          <div class="card" @click="datePickerShow1=true">
            <van-icon name="https://static.ainvestcn.com/icon/c1.png" size="18px" style="margin-left:10px;margin-right:10px" />
            <input type="text" :placeholder="dateOfDatetime(qrDate1)">
            <!-- 最新数据 -->
          </div>
          <van-collapse v-model="activeNames">
            <van-collapse-item v-for="(item,index) in history.transaction_records" :key="index" :name="index+1">
              <div slot="title">
                {{ item.SecShortName }}
                <van-tag plain>
                  {{ item.As_Of_Date }}
                </van-tag>
              </div>
              <van-row>
                <van-col>
                  所属行业：{{ item.Industry }}
                  <br>
                  当日盈亏(百分比)：{{ item.Today_Profit_and_Loss ? item.Today_Profit_and_Loss : '暂无数据' }} 
                  <br>
                  累积盈亏(百分比)：{{ item.Total_Profit_and_Loss ? item.Total_Profit_and_Loss : '暂无数据' }}
                </van-col>

              </van-row>
            </van-collapse-item>
          </van-collapse>
          <div class="card" style="padding-left:10px">默认显示最近持仓</div>
        </van-tab>
        <van-tab title="历史调仓">
          <div class="card" @click="datePickerShow2=true">
            <van-icon name="https://static.ainvestcn.com/icon/c1.png" size="18px" style="margin-left:10px;margin-right:10px" />
            <input type="text" :placeholder="dateOfDatetime(qrDate2)">
            <!-- 最新数据 -->
          </div>
          <van-collapse v-model="activeNames">
            <van-collapse-item v-for="(item,index) in warehouse.adjust_records" :key="index" :name="index+1">
              <div slot="title">
                {{ item.SecShortName }}
                <van-tag plain>
                  {{ item.As_Of_Date }}
                </van-tag>
              </div>
              <van-row>
                <van-col>
                  调仓日期：{{ item.As_Of_Date }}
                  <br>
                  买 \ 卖：{{ item.Transaction_class }}
                  <br>
                </van-col>
              </van-row>
            </van-collapse-item>
            <div class="card" style="padding-left:10px">默认显示最近调仓</div>
          </van-collapse>
        </van-tab>
      </van-tabs>
    </div>

    <div>
      <van-goods-action>
        <!-- <van-goods-action-icon icon="chat-o" text="客服" /> -->
        <!-- <van-goods-action-icon v-if="isvip" text="vip" @click="vip()">
          <van-icon slot="icon" name="https://static.ainvestcn.com/icon/vip-a.png" />
        </van-goods-action-icon> -->

        <!-- <van-goods-action-button type="warning" text="收藏" @click="onClickButton" /> -->
        <!-- <van-button>123</van-button> -->
        <!-- <van-goods-action-button v-if="!isvip" type="danger" text="成为VIP" @click="vip()" />
        <van-goods-action-button :disabled="isvip?false:true" type="danger" text="VIP免费订阅" @click="subsecribe()" /> -->
        <van-goods-action-icon text="返回" @click="back()">
          <van-icon slot="icon" name="home-o" />
        </van-goods-action-icon>
        <van-goods-action-icon text="">
          <!-- <van-icon slot="icon" name="https://static.ainvestcn.com/h5/%E7%AB%96%E7%BA%BF.png" /> -->
        </van-goods-action-icon>
        <!--  todo 如果 已订阅策略和当前浏览策略一致，订阅按钮变灰 -->
        <!-- <template v-if="islogin">
          <van-goods-action-button v-if="isvip||exptime<=0" type="danger" @click="subsecribe()"> 订阅策略 </van-goods-action-button>
          <van-goods-action-button v-if="!isvip&&exptime>0" type="danger" @click="pay()"> 订阅策略 </van-goods-action-button>
        </template>

        <van-goods-action-button v-if="!islogin" type="danger" @click="reg()"> 注册体验 </van-goods-action-button> -->
        <!-- c:{{ canOrder }} v:{{ isvip }} e:{{}} -->
<!--        <van-goods-action-button v-if="!subscribed" type="danger" @click="subsecribe()"> 订阅策略 </van-goods-action-button>-->
<!--        <van-goods-action-button v-if="subscribed" type="danger"> 已订阅 </van-goods-action-button>-->
        <!-- 如果成为isvip 并且 vip -->
        <!-- <van-goods-action-button v-if="!canOrder" type="danger" @click="vip()"> 成为会员 </van-goods-action-button> -->
      </van-goods-action>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {
	GoodsAction,
	GoodsActionIcon,
	GoodsActionButton,
	Button,
	Card,
	Tag,
	Cell, Toast, Dialog,
	Tab, Tabs,
	Collapse, CollapseItem,
	DatetimePicker,
	Popup
} from 'vant'
import echarts from 'echarts'
import { fetchStrategy, islogin,
	choseStrategy, fetchHistory,
	fetchWarehouseHistory, fetchHistoryWithDate,
	fetchWarehouseHistoryWithDate, toggleYidao, isvipApi, getUserInfofromOldServer } from '@/api/theApi'
// const Base64 = require('js-base64').Base64

Vue.use(Toast)
export default {
	components: {
		[GoodsAction.name]: GoodsAction,
		[GoodsActionIcon.name]: GoodsActionIcon,
		[GoodsActionButton.name]: GoodsActionButton,
		[Button.name]: Button,
		[Card.name]: Card,
		[Tag.name]: Tag,
		[Cell.name]: Cell,
		[Tab.name]: Tab,
		[Tabs.name]: Tabs,
		[Collapse.name]: Collapse,
		[CollapseItem.name]: CollapseItem,
		[DatetimePicker.name]: DatetimePicker,
		[Popup.name]: Popup
	},

	data() {
		return {
			strategyId: '',
			classId: '',
			chart: '',
			strategyinfo: {},
			history: [],
			warehouse: [], // 待改名
			active: 2,
			activeNames: ['1'],
			activeNames2: ['1'],
			qrDate1: new Date(),
			qrDate2: new Date(),
			minDate: new Date('2000', '01', '01'),
			maxDate: new Date(),
			datePickerShow1: false,
			datePickerShow2: false,
			isvip: false,
			exptime: '',
			islogin: false,
			subscribed: false
		}
	},
	computed: {
		cubeWidth: () => {
			return document.body.clientWidth
		},
		cubeHeight: () => {
			return document.body.clientHeight
		},
		devicePixelRatio: () => {
			return window.devicePixelRatio
		},
		login_status: () => {
			const islogin = localStorage.getItem('login_status')
			// return islogin === 'true'

			if (islogin === 'true') {
				// console.log('isvip', this.isvip)
				// if (this.isvip || this.exptime <= 0) {
				//   return true
				// }
				// if (!this.isvip && this.exptime > 0) {
				//   return false
				// }
				return true
			} else {
				return false
			}

			// 会员 可以order
			// 非会员，试用期内 ，可以order
			// 过期，不可以order
		}

	},
	created() {
		// console.log(this.$route.params.id)
		this.initData().then(() => {
			// console.log(this.strategyinfo.data)
			this.drawChart(this.strategyinfo.data)
		})
		this.fetchHistory()
		// 获取并缓存策略id
		this.fetchWarehouseHistory()

		this.isloginFun()
		this.isvipFun()

		// console.log(this.islogin)
		// 获取策略数据

		// 获取绘图数据
	},
	mounted() {
		console.log('mounted::isvip', this.isvip)
		console.log('mounted::islogin', this.islogin)
	},
	updated() {
	},
	beforeDestroy() {
		if (!this.chart) {
			return
		}
		this.chart.dispose()
		this.chart = null
	},
	methods: {
		reg() {
			this.$router.push({
				path: '/regLogin'
			})
		},
		back() {
			// this.$router.go(-1)
			this.$router.push({
				path: '/strategylist'
			})
		},
		async isloginFun() {
			const vm = this
			const isloginRes = await islogin()
			if (!isloginRes.login_status) {
				this.islogin = false
			} else {
				this.islogin = true
				const originUname = localStorage.getItem('username')
				if (originUname) {
					const uInfo1 = await getUserInfofromOldServer()
					vm.subscribed = uInfo1.strategy_name === vm.strategyinfo.strategy_name
				}
			}
		},
		async isvipFun() {
			const username = localStorage.getItem('username')
			if (username) {
				const rslt = await isvipApi(username)
				if (rslt.data.isvip === '1') {
					console.log('vip', rslt.data.isvip)
					this.isvip = true
				} else {
					this.isvip = false
				}
			}
		},
		// 成为vip
		vip() {
			// 检验是否可以进入支付流程

			//
			this.$router.push({
				path: '/pay'
			})
		},
		confirmDate: async function(timeType, showType) {
			this[showType] = false

			// 判断this.qrDate是否为交易日，如果不是交易日，按照前一个交易日的结果获取
			let thedate = this.dateOfDatetime(this[timeType])
			// if (new Date(this[timeType]).getDay() === 6) {
			// 	thedate = this.dateOfDatetime(new Date(this[timeType] - 1 * 24 * 60 * 60 * 1000))
			// }

			// if (new Date(this[timeType]).getDay() === 0) {
			// 	thedate = this.dateOfDatetime(new Date(this[timeType] - 2 * 24 * 60 * 60 * 1000))
			// }
			// console.log(thedate)
			// console.log(timeType)
			if (timeType === 'qrDate1') {

				const hisrslt = await fetchHistoryWithDate(this.$route.params.id, this.$route.params.cid, thedate)

				this.history = hisrslt
			} else {

				const adjustrslt = await fetchWarehouseHistoryWithDate(this.$route.params.id, this.$route.params.cid, thedate)
				if (adjustrslt.adjust_records.length !== 0) {
					let item = adjustrslt.adjust_records[0];
					if (this.dateOfDatetime(item.As_Of_Date) !== thedate) {
						Toast.fail('当前日期无记录。')
					}
				} else {

					Toast.fail('当前日期无记录。')
				}

				this.warehouse = adjustrslt
			}

		},
		cancelDate: function(timeType, showType) {
			this[timeType] = new Date()
			this[showType] = false
		},
		formatter(type, value) {
			if (type === 'year') {
				return `${value}年`
			} else if (type === 'month') {
				return `${value}月`
			} else if (type === 'day') {
				return `${value}日`
			}
			return value
		},
		dateOfDatetime(datetime) {
			const theDatetime = new Date(datetime)
			const year = theDatetime.getFullYear() + '-'
			const month = theDatetime.getMonth() + 1 + '-'
			const date = theDatetime.getDate()
			return year + month + date
		},
		theislogin() {
			islogin().then(
				res => {
					if (res.login_status) {
						return true
					} else {
						return false
					}
				}
			)
		},
		async initData() {
			this.strategyId = await this.$route.params.id
			this.classId = await this.$route.params.cid
			const res1 = await fetchStrategy(this.strategyId, 9, this.classId)
			const StrategyInfo = res1
			this.strategyinfo = StrategyInfo
			const exptime = localStorage.getItem('exptime')
			this.exptime = exptime
			console.log(exptime)
			// console.log(StrategyInfo)
		},
		init() {
			// 页面初始化
			// 1. 通过路由获取当前策略 id
			// let strategy_id = this.$route.query.id
		},
		drawChart(data) {
			this.chart = echarts.init(document.getElementById('pig'))
			// console.log(this.strategyinfo.data.length)
			this.chart.setOption({
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					data: ['中证500', '策略']
				},
				dataZoom: [
					{
						startValue: ''
					},
					{
						type: 'inside'
					}
				],
				xAxis: {
					// type: 'category',
					// boundaryGap: false,
					// data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
					data: data.map(item => {
						return item.as_of_date
					}),
					spliteLine: {
						show: true,
						lineStyle: {
							color: ['grey'],
							width: 1,
							type: 'solid'
						}
					}
				},
				yAxis: {
					name: '累计收益率'
				},
				series: [
					{
						name: '中证500',
						type: 'line',
						itemStyle: {
							color: '#1D2088'
						},
						data: data.map(function(item) {
							return item.market
						}),
						splitLine: {
							show: true,
							lineStyle: {
								color: ['grey'],
								width: 1,
								type: 'solid'
							}
						},
						areaStyle: {
							// color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
						}
					},
					{
						name: '策略',
						type: 'line',
						itemStyle: {
							color: '#FF3600'
						},
						data: data.map(function(item) {
							return item.predict_strategy_value
						}),
						areaStyle: {
							// color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
						}
					}
				]
			})
		},
		async subsecribe() {
			// 检查登陆
			const isloginRes = await islogin()

			if (!isloginRes.login_status) {
				Toast.fail('尚未登陆\n即将跳转\n登陆界面')
				this.$router.push({
					path: '/login'
				})
			} else {
				const choseRslt = await choseStrategy(this.strategyId, this.classId)
				if (choseRslt.data === 'success') {
					Toast.success('订阅成功')
					setTimeout(() => {
						Dialog.confirm({
							title: '提示',
							message: '是否立刻开启一刀智投',
							confirmButtonText: '立刻开启',
							cancelButtonText: '暂不开启'
						}).then(() => {
							toggleYidao(1)// 开启一刀

							const url = '/user/1'
							this.$router.push({
								path: url
							})
						}).catch(() => {
							toggleYidao(0)// 关闭一刀
							this.$router.push({
								path: '/user'
							})
						// on cancel
						})
					}, 2000)
				} else {
					Toast.fail('请先绑定券商')
					setTimeout(() => {
						this.$router.push({
							path: '/user'
						})
					}, 1500)
				}

				// const tempUsername = localStorage.getItem('username')
			}
			// let islogin = await islogin().;

			// 调用接口订阅策略 修改策略接口
			// 订阅成功后检查是否开启了自动交易,如果已经开启自动交易,提示已经开启自动交易,确保账户有足够资金,明天将使用该策略选股交易
			// 跳转到用户中心
		},
		async fetchHistory() {
			const rslt = await fetchHistory(this.$route.params.id, this.$route.params.cid)
			this.history = rslt
		},
		async fetchWarehouseHistory() {
			const rslt = await fetchWarehouseHistory(this.$route.params.id, this.$route.params.cid)
			this.warehouse = rslt
		}
	}
}
</script>
<style lang="less" scoped>
.top {
  // bottom: 4rem;
  // height:3.5rem;
  height: 7.5vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.99);
  .logo{
    width:44vw;
    // height: 5vh;
    margin-left:20.5vw;
    margin-top:1.2vh;
  }
  .returns{
    width: 4vh;
    // height: 5vh;
    margin-bottom: 0.4vh;
  }
}
.card {
  margin: 2px;
  padding: 2px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 8px 12px #ebedf0;
}
.card-title {
  margin-bottom: 10px;
}
.cardline {
  margin: 0 0 5px 10px;
}
</style>
